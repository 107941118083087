import {Inject, Injectable} from "@angular/core";
import {AddressCandidateAdapter} from "../address-candidate.adapter";
import {Observable} from "rxjs";
import {AddressCandidate, AddressCandidates} from "../../model/address-candidate.model";
import {ConverterService, OccEndpointsService} from "@spartacus/core";
import {tap, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ConnectionQuotationToolConfig} from "../../connection-quotation-tool.module";
import {NetworkAreaAdapter} from "../network-area.adapter";
import {GpsCoordinates} from "../../model/gps-coordinates.mode";
import {NetworkArea} from "../../model/network-area.model";
import {CostsIndicationAdapter} from "../costs-indication.adapter";
import {CostsIndication, CostsIndicationRequest} from "../../model/cost-indication.model";


@Injectable()
export class CostsIndicationImplAdatper implements CostsIndicationAdapter {
  constructor(
    protected http: HttpClient,
    @Inject('ConnectionQuotationToolConfigService') protected config : ConnectionQuotationToolConfig
  ) {
  }

  retrieve(costsIndicationRequest: CostsIndicationRequest): Observable<CostsIndication> {
    return this.http.post<CostsIndication>(this.config.retrieveCostsIndication, costsIndicationRequest);
  }
}

