import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuotationStoreModule} from "./store/quotation-store.module";
import {ConnectionUsageComponent} from './cms-components/connection-usage/connection-usage.component';
import {ConnectionCapacityComponent} from './cms-components/connection-capacity/connection-capacity.component';
import {AuthGuard, ConfigModule, FeaturesConfigModule, I18nModule, OccEndpoints, UrlModule} from "@spartacus/core";
import {StoreModule} from "@ngrx/store";
import {quotationReducer} from "./store/reducers";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import { ConnectionEnergyComponent } from './cms-components/connection-energy/connection-energy.component';
import { ConnectionTypeComponent } from './cms-components/connection-type/connection-type.component';
import { ConnectionLocationComponent } from './cms-components/connection-location/connection-location.component';
import {FormsModule} from "@angular/forms";
import {AddressCandidateImplAdatper} from "./connectors/impl/address-candidate-impl.adatper";
import {EffectsModule} from "@ngrx/effects";
import {AddressCandidateEffects} from "./store/effects/address-candidate.effects";
import {AddressCandidateConnector} from "./connectors/address-candidate.connector";
import {AddressCandidateAdapter} from "./connectors/address-candidate.adapter";
import {NetworkAreaAdapter} from "./connectors/network-area.adapter";
import {NetworkAreaImplAdatper} from "./connectors/impl/network-area-impl.adatper";
import {NetworkAreaConnector} from "./connectors/network-area.connector";
import {NetworkAreaEffects} from "./store/effects/network-area.effects";
import {CostsIndicationEffects} from "./store/effects/costs-indication.effects";
import {CostsIndicationAdapter} from "./connectors/costs-indication.adapter";
import {CostsIndicationImplAdatper} from "./connectors/impl/costs-indication-impl.adatper";
import {CostsIndicationConnector} from "./connectors/costs-indication.connector";
import { ConnectionIndicationComponent } from './cms-components/connection-indication/connection-indication.component';
import {
  AddressFormModule,
  B2cStorefrontModule,
  CardModule, CartComponentModule,
  CartNotEmptyGuard,
  CheckoutAuthGuard,
  CheckoutStepType,
  DeliveryModePreferences,
  DeliveryModeSetGuard, IconModule, ListNavigationModule,
  PaymentDetailsSetGuard,
  ReviewSubmitComponent,
  ShippingAddressSetGuard,
  SpinnerModule
} from "@spartacus/storefront";
import {environment} from "../../environments/environment";
import {translationChunksConfig, translations} from "@spartacus/assets";
import {BillingAddressComponent} from "./cms-components/checkout/billing-address/billing-address.component";
import {ReviewQuoteSubmitComponent} from "./cms-components/checkout/review-submit/review-quote-submit.component";
import {RouterModule} from "@angular/router";
import {CheckoutQuoteSummaryComponent} from "./cms-components/checkout/checkout-quote-summary/checkout-quote-summary.component";
import {QuoteSummaryComponent} from "./cms-components/cart/quote-summary/quote-summary.component";
import {QuoteEntryComponent} from "./cms-components/cart/quote-entry/quote-entry.component";
import {QuoteDetailItemsComponent} from "./cms-components/quote/quote-detail-items/quote-detail-items.component";
import {QuoteHistoryComponent} from "./cms-components/quote/quote-history/quote-history.component";

export interface ConnectionQuotationToolConfig {
  findAddressCandidatesEndPoint: string,
  checkNetworkArea: string
  retrieveCostsIndication: string
  submitConnectionConfiguration: string;
}

export const extendedCheckoutStepTypes = {
  ...CheckoutStepType,
  BILLING_ADDRESS: "billingAddress" as CheckoutStepType
}

export const ConnectionQuotationToolConfigService = new InjectionToken<ConnectionQuotationToolConfig>("ConnectionQuotationToolConfig");

export const translationsOverwrites = {
  en: {
    address: {
      addressCard: {
        contact: 'Contact address:',
        billing: 'Billing address:',
      }
    },
    checkout: {
      checkoutAddress: {
        shippingAddress: 'Contact Address',
        selectYourShippingAddress: 'Select your Contact Address',
        defaultShippingAddress: 'Default Address',
        shipToThisAddress: 'Use this address',
        billingAddress: 'Billing Address',
        selectYourBillingAddress: 'Select your Billing Address',
        defaultVillingAddress: 'Default Address',
      },
      checkoutReview: {
        editContactAddress: 'Edit contact address',
        editBillingAddress: 'Edit billing address',
        placeOrder: 'Request quote',
      },
      checkoutProgress: {
        shippingAddress: 'Contact Address',
        deliveryMode: 'Delivery mode',
        paymentDetails: 'Payment details',
        reviewOrder: 'Review quote request',
        billingAddress: 'Billing Address'
      },
      checkout: {
        backToCart: 'Back',
      },
    },
    quote: {
      quote: {
        quoteSummary: 'Summary',
        subtotal: 'Estimated total costs:',
        connectionType: 'Connection type:',
        location: 'Location:'
      },
    },
    myAccount: {
      orderDetails: {
        orderId: 'Quote #',
        pending: ''
      },
      orderHistory: {
        orderHistory: 'Quotes',
        orderId: 'Quote #',
        quoteType: 'Type',
        quoteDescription: 'Description'
      }
    },
    common: {
      pageMetaResolver: {
        checkout: {
          title: 'Request quote',
        }
      },
      miniLogin: {
        signInRegister: 'My Liander',
      },
    }
  }
}

@NgModule({
  declarations: [
    ConnectionUsageComponent,
    ConnectionCapacityComponent,
    ConnectionEnergyComponent,
    ConnectionTypeComponent,
    ConnectionLocationComponent,
    ConnectionIndicationComponent,
    BillingAddressComponent,
    ReviewQuoteSubmitComponent,
    CheckoutQuoteSummaryComponent,
    QuoteSummaryComponent,
    QuoteEntryComponent,
    QuoteDetailItemsComponent,
    QuoteHistoryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    QuotationStoreModule,
    StoreModule.forFeature("quotationState", quotationReducer),
    EffectsModule.forFeature([AddressCandidateEffects, NetworkAreaEffects, CostsIndicationEffects]),
    B2cStorefrontModule.withConfig({
      context: {
        ['language']: ['en'],
        ['currency']: ['EUR']
      },
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en'
      },
      // i18n: {
      //   resources: translationsOverwrites,
      //   chunks: translationChunksConfig,
      //   fallbackLang: 'en'
      // },
      routing: {
        routes: {
          checkoutBillingAddress: {paths: ['checkout/billing-address']},
        }
      },
      checkout: {
        steps: [
          {
            id: 'shippingAddress',
            name: 'checkoutProgress.shippingAddress',
            routeName: 'checkoutShippingAddress',
            type: [extendedCheckoutStepTypes.SHIPPING_ADDRESS],
          },
          {
            id: 'billingAddress',
            name: 'checkoutProgress.billingAddress',
            routeName: 'checkoutBillingAddress',
            type: [extendedCheckoutStepTypes.BILLING_ADDRESS],
          },
          // {
          //   id: 'deliveryMode',
          //   name: 'checkoutProgress.deliveryMode',
          //   routeName: 'checkoutDeliveryMode',
          //   type: [CheckoutStepType.DELIVERY_MODE],
          // },
          // {
          //   id: 'paymentDetails',
          //   name: 'checkoutProgress.paymentDetails',
          //   routeName: 'checkoutPaymentDetails',
          //   type: [CheckoutStepType.PAYMENT_DETAILS],
          // },
          {
            id: 'reviewOrder',
            name: 'checkoutProgress.reviewOrder',
            routeName: 'checkoutReviewOrder',
            type: [CheckoutStepType.REVIEW_ORDER],
          },
        ],
        express: false,
        defaultDeliveryMode: [DeliveryModePreferences.FREE],
        guest: false,
      }
    }),
    ConfigModule.withConfig({
      i18n: {
        resources: translationsOverwrites,
      },
      cmsStructure: {
        slots: {
          SiteLogin: {
            componentIds: [],
          },
          SiteLinks: {
            componentIds: ['LoginComponent'],
          }
        },
      },

      layoutSlots: {
        header: {
          md: {
            slots: [
              'SiteContext',
              'SiteLinks',
              'SiteLogo',
              'SiteLogin',
              'NavigationBar',
            ],
          },
          xs: {
            slots: ['PreHeader', 'SiteLogo', 'SearchBox', 'MiniCart'],
          },
        },
      },

      cmsComponents: {
        ConnectionUsageComponent: {
          component: ConnectionUsageComponent
        },
        ConnectionCapacityComponent: {
          component: ConnectionCapacityComponent
        },
        ConnectionEnergyComponent: {
          component: ConnectionEnergyComponent
        },
        ConnectionTypeComponent: {
          component: ConnectionTypeComponent
        },
        ConnectionLocationComponent: {
          component: ConnectionLocationComponent
        },
        ConnectionIndicationComponent: {
          component: ConnectionIndicationComponent
        },
        CheckoutBillingAddress: {
          component: BillingAddressComponent
        },
        CheckoutReviewOrder: {
          component: ReviewQuoteSubmitComponent,
          guards: [
            CheckoutAuthGuard,
            CartNotEmptyGuard,
            ShippingAddressSetGuard,
//            DeliveryModeSetGuard,
//            PaymentDetailsSetGuard,
          ],
        },
        AccountOrderDetailsItemsComponent: {
          component: QuoteDetailItemsComponent,
        },
        AccountOrderHistoryComponent: {
          component: QuoteHistoryComponent,
          guards: [AuthGuard],
        },
        // AccountOrderDetailsTotalsComponent: {
        //   component: OrderDetailTotalsComponent,
        // },
        CheckoutOrderSummary: {
          component: CheckoutQuoteSummaryComponent,
        }
      },
    }),
    I18nModule,
    CardModule,
    AddressFormModule,
    SpinnerModule,
    UrlModule,
    RouterModule,
    CartComponentModule,
    FeaturesConfigModule,
    IconModule,
    ListNavigationModule,
  ],
  providers: [
    {
      provide: AddressCandidateAdapter,
      useClass: AddressCandidateImplAdatper,
    },
    AddressCandidateConnector,
    {
      provide: NetworkAreaAdapter,
      useClass: NetworkAreaImplAdatper,
    },
    NetworkAreaConnector,
    {
      provide: CostsIndicationAdapter,
      useClass: CostsIndicationImplAdatper,
    },
    CostsIndicationConnector,

  ],
  entryComponents: [
    ConnectionUsageComponent,
    ConnectionCapacityComponent,
    ConnectionEnergyComponent,
    ConnectionTypeComponent,
    ConnectionLocationComponent,
    ConnectionIndicationComponent,
    BillingAddressComponent,
    ReviewQuoteSubmitComponent,
    CheckoutQuoteSummaryComponent,
    QuoteSummaryComponent,
    QuoteEntryComponent,
    QuoteDetailItemsComponent,
    QuoteHistoryComponent
  ],
})
export class ConnectionQuotationToolModule {
  static withConfig(config : ConnectionQuotationToolConfig) : ModuleWithProviders<ConnectionQuotationToolModule> {
    return {
      ngModule: ConnectionQuotationToolModule,
      providers: [
        {
          provide: 'ConnectionQuotationToolConfigService',
          useValue: config,
        },
        {
          provide: AddressCandidateAdapter,
          useClass: AddressCandidateImplAdatper,
        },
        AddressCandidateConnector
      ]
    }
  }
}


