import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {QuotationState} from "../../store/quotation.state";
import {select, Store} from "@ngrx/store";
import {registerQuotationComponent} from "../index";
import {ConnectionType, ConnectionUsage} from "../../model/quotation-model";
import {QuotationActions} from "../../store/actions";
import {CmsComponentData} from "@spartacus/storefront";
import {CmsFlexComponent} from "../../../../acorelsalesdemo/model/cms-models";

@Component({
  selector: 'app-connection-type',
  templateUrl: './connection-type.component.html',
  styleUrls: ['./connection-type.component.scss']
})
export class ConnectionTypeComponent implements OnInit {

  quotationModel$: Observable<QuotationState>;

  visible$ = true;

  constructor(private store: Store<{ quotationState: QuotationState }>,
              public component: CmsComponentData<CmsFlexComponent>) {
    this.quotationModel$ = store.pipe(select('quotationState'));
    registerQuotationComponent(this);
  }

  ngOnInit() {
    this.quotationModel$.subscribe(
      model => {
        this.visible$ = (model.model.electric != null && model.model.electric.connectionCapacity != null)
      }
    )
  }

  selectType(connectionType: string) {
    var connectionTypeEnum : ConnectionType = ConnectionType[connectionType];
    this.store.dispatch(QuotationActions.setConnectionType({connectionType: connectionTypeEnum}));
  }

}
