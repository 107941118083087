import {Injectable} from "@angular/core";
import {AddressCandidateAdapter} from "./address-candidate.adapter";
import {Observable} from "rxjs";
import {AddressCandidate} from "../model/address-candidate.model";

@Injectable()
export class AddressCandidateConnector {

  constructor(protected adapter: AddressCandidateAdapter) {}

  find(zipcode: string, streetNumber: string, addition: string): Observable<AddressCandidate> {
    return this.adapter.find(zipcode, streetNumber, addition);
  }

}
