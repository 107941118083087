import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {quotationReducer} from "./reducers";
import {EffectsModule} from "@ngrx/effects";
import {AddressCandidateEffects} from "./effects/address-candidate.effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
})
export class QuotationStoreModule { }
