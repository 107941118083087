import { Component, OnInit } from '@angular/core';
import {select, Store} from "@ngrx/store";
import {QuotationState} from "../../store/quotation.state";
import {Observable} from "rxjs";
import {ConnectionUsage, QuotationModel} from "../../model/quotation-model";
import {QuotationActions} from "../../store/actions";
import {QuotationComponent, registerQuotationComponent} from "../index";
import {CmsComponentData} from "@spartacus/storefront";
import {CmsFlexComponent} from "../../../../acorelsalesdemo/model/cms-models";

@Component({
  selector: 'app-connection-usage',
  templateUrl: './connection-usage.component.html',
  styleUrls: ['./connection-usage.component.scss']
})
export class ConnectionUsageComponent implements OnInit, QuotationComponent {

  quotationModel$: Observable<QuotationState>;

  visible$ = true;

  constructor(private store: Store<{ quotationState: QuotationState }>,
              public component: CmsComponentData<CmsFlexComponent>) {
    this.quotationModel$ = store.pipe(select('quotationState'));
    registerQuotationComponent(this);
  }

  ngOnInit() {
    this.quotationModel$.subscribe(
      model => {
        this.visible$ = (model.model.electric != null)
      }
    )
  }

  selectUsage(usage: string) {
    var connectionUsage : ConnectionUsage = ConnectionUsage[usage];
    this.store.dispatch(QuotationActions.setConnectionUsage({usage: connectionUsage}));
  }
}
