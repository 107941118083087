import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {QuotationState} from "../../store/quotation.state";
import {select, Store} from "@ngrx/store";
import {registerQuotationComponent} from "../index";
import {QuotationActions} from "../../store/actions";
import {CmsComponentData} from "@spartacus/storefront";
import {CmsFlexComponent} from "../../../../acorelsalesdemo/model/cms-models";

@Component({
  selector: 'app-connection-energy',
  templateUrl: './connection-energy.component.html',
  styleUrls: ['./connection-energy.component.scss']
})
export class ConnectionEnergyComponent implements OnInit {

  quotationModel$: Observable<QuotationState>;

  visible$ = true;

  constructor(
    private store: Store<{ quotationState: QuotationState }>,
    public component: CmsComponentData<CmsFlexComponent>
    ) {
    this.quotationModel$ = store.pipe(select('quotationState'));
    registerQuotationComponent(this);
  }

  ngOnInit() {
    this.quotationModel$.subscribe(
      model => {
        //
      }
    )
  }

  selectElectric() {
    this.store.dispatch(QuotationActions.addElectric());
  }

  selectGas() {
    this.store.dispatch(QuotationActions.addGas());
  }

}
