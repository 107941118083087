import {Inject, Injectable} from "@angular/core";
import {AddressCandidateAdapter} from "../address-candidate.adapter";
import {Observable} from "rxjs";
import {AddressCandidate, AddressCandidates} from "../../model/address-candidate.model";
import {ConverterService, OccEndpointsService} from "@spartacus/core";
import {tap, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ConnectionQuotationToolConfig} from "../../connection-quotation-tool.module";


@Injectable()
export class AddressCandidateImplAdatper implements AddressCandidateAdapter {
  constructor(
    protected http: HttpClient,
    @Inject('ConnectionQuotationToolConfigService') protected config : ConnectionQuotationToolConfig
  ) {
  }

  find(zipcode: string, streetNumber: string, addition: string): Observable<AddressCandidate> {
    return this.http
      .get<AddressCandidates>(this.config.findAddressCandidatesEndPoint, { params: {
          singleLine: zipcode + " " + streetNumber + " " + ((addition)? addition : ''),
          Country: 'NL',
          f: 'json',
          outFields: '*',
          category: '',
          countryCode: 'NL',
          maxLocations: '1'
        }})
      .pipe(
        map<AddressCandidates, AddressCandidate>(data => data.candidates[0])
      );
  }
}

