import {Injectable} from "@angular/core";
import {AddressCandidateAdapter} from "./address-candidate.adapter";
import {Observable} from "rxjs";
import {AddressCandidate} from "../model/address-candidate.model";
import {GpsCoordinates} from "../model/gps-coordinates.mode";
import {NetworkArea} from "../model/network-area.model";
import {NetworkAreaAdapter} from "./network-area.adapter";

@Injectable()
export class NetworkAreaConnector {

  constructor(protected adapter: NetworkAreaAdapter) {}

  check(gpsCoordinates: GpsCoordinates): Observable<NetworkArea> {
    return this.adapter.check(gpsCoordinates);
  }

}
