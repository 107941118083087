import {Action, createAction, props} from "@ngrx/store";
import {ConnectionType, ConnectionUsage} from "../../model/quotation-model";
import {AddressCandidate} from "../../model/address-candidate.model";
import {GpsCoordinates} from "../../model/gps-coordinates.mode";
import {NetworkArea} from "../../model/network-area.model";
import {CostsIndicationRequest, CostsIndication} from "../../model/cost-indication.model";
import {QuotationState} from "../quotation.state";

export const addElectric = createAction('[Quotation] add electric');
export const addGas = createAction('[Quotation] add gas');

export const setConnectionUsage = createAction('[Quotation] set connection usage',
  props<{usage: ConnectionUsage}>());

export const setConnectionType = createAction('[Quotation] set connection type',
  props<{connectionType: ConnectionType}>());

export const setConnectionCapacity = createAction('[Quotation] set connection capacity',
  props<{connectionCapacity: string}>());

export const reset = createAction('[Quotation] Reset');
export const load = createAction('[Quotation] Load');

export const findAddressCandidate = createAction('[Quotation] find address candidate',
  props<{zipcode: string, steetNumber: string, addtion: string}>());

export const findAddressCandidateSuccess = createAction('[Quotation] find address candidate success',
  props<{candidate: AddressCandidate}>());

export const findAddressCandidateFail = createAction('[Quotation] find address candidate error',
  props<{error: any}>());

export const checkNetworkArea = createAction('[Quotation] check network area',
  props<{gspCoordinates: GpsCoordinates}>());

export const checkNetworkAreaSuccess = createAction('[Quotation] check network area success',
  props<{networkArea: NetworkArea}>());

export const checkNetworkAreaFail = createAction('[Quotation] check network area error',
  props<{error: any}>());

export const retrieveCostsIndication = createAction('[Quotation] retrieve costs indication',
  props<{quotationState: QuotationState}>());

export const retrieveCostsIndicationSuccess = createAction('[Quotation] retrieve costs indication success',
  props<{costsIndication: CostsIndication}>());

export const retrieveCostsIndicationFail = createAction('[Quotation] retrieve costs indication error',
  props<{error: any}>());




