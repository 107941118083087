import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
   
import { AppComponent } from './app.component';
import { translations, translationChunksConfig } from '@spartacus/assets';
import {B2cStorefrontModule, CheckoutStepType, DeliveryModePreferences} from '@spartacus/storefront';
import {environment} from "../environments/environment";
import {ProductConfigurationModule} from "../productconfiguration/product-configuration.module";
import {
  ConnectionQuotationToolModule,
  translationsOverwrites
} from "../liander/connection-quotation-tool/connection-quotation-tool.module";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {ConfigModule} from "@spartacus/core";
   
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    StoreDevtoolsModule.instrument({
      maxAge: 500, // Retains last 25 states
    }),
    BrowserModule,
    B2cStorefrontModule.withConfig({
      authentication: {
        client_id: 'mobile_android',
        client_secret: 'secret'
      },
      backend: {
        occ: {
          baseUrl: environment.occBaseUrl,
          prefix: environment.prefix,
        }
      },
      context: {
        baseSite: environment.baseSite
      },
      features: {
        level: '1.4',
        anonymousConsents: true
      },
    }),
    ConnectionQuotationToolModule.withConfig(
      {
        findAddressCandidatesEndPoint: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates',
        checkNetworkArea: 'https://locatie-service-acc.web.liander.nl/api/netwerkgebied',
        retrieveCostsIndication: 'https://kostenindicatie-service-acc.web.liander.nl/api',
        submitConnectionConfiguration: "https://localhost:9002/rest/v2/electronics-spa/connections"
      }
    ),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
