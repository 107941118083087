import {ChangeDetectionStrategy, Component} from "@angular/core";
import {OrderHistoryComponent} from "@spartacus/storefront";
import {RoutingService, TranslationService, UserOrderService} from "@spartacus/core";


@Component({
  selector: 'cx-order-history',
  templateUrl: './quote-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteHistoryComponent extends OrderHistoryComponent {

  constructor(routing: RoutingService, userOrderService: UserOrderService, translation: TranslationService) {
    super(routing, userOrderService, translation)
  }
}
