import {ConnectionType, ConnectionUsage, QuotationModel} from "../../model/quotation-model";
import {Action, createReducer, on} from "@ngrx/store";
import {QuotationActions} from "../actions";
import {QuotationState} from "../quotation.state";
import {Address} from "@spartacus/core";


export const initialState : QuotationState = {
  model: {
    electric: null,
    gas: null
  },
  addressCandidate: null,
  networkArea: null,
  costsIndication: null,
  costsIndicationIsLoading: false
}

const _quotationReducer = createReducer(initialState,
  on(QuotationActions.reset, state => initialState),
  on(QuotationActions.addElectric, state => (
      {...state, model: {...state.model, electric: {}}}
    )
  ),
  on(QuotationActions.setConnectionUsage, (state, {usage}) => (
    {...state, model: {...state.model, electric: {...state.model.electric, connectionUsage: usage}}}
  )),
  on(QuotationActions.setConnectionType, (state, {connectionType}) => (
    {...state, model: {...state.model, electric: {...state.model.electric, connectionType: connectionType}}}
  )),
  on(QuotationActions.setConnectionCapacity, (state, {connectionCapacity}) => (
    {...state, model: {...state.model, electric: {...state.model.electric, connectionCapacity: connectionCapacity}}}
  )),
  on(QuotationActions.findAddressCandidate, (state) => (
    {...state, addressCandidate: null, networkArea: null, costsIndication: null}
  )),
  on(QuotationActions.findAddressCandidateSuccess, (state, {candidate}) => (
    {...state, addressCandidate: {...candidate}, costsIndicationIsLoading: false}
  )),
  // on(QuotationActions.checkNetworkArea, (state, {gspCoordinates}) => (
  //   {...state, networkArea: null, costsIndication: null}
  // )),
  on(QuotationActions.checkNetworkAreaSuccess, (state, {networkArea}) => (
    {...state, networkArea: {...networkArea}}
  )),
  on(QuotationActions.retrieveCostsIndication, (state, {quotationState}) => (
    {...state, costsIndication: null, costsIndicationIsLoading: true}
  )),
  on(QuotationActions.retrieveCostsIndicationSuccess, (state, {costsIndication}) => (
    {...state, costsIndication: {...costsIndication}}
  )),
);

export function quotationReducer(state: QuotationState | undefined, action: Action) {
  return _quotationReducer(state, action);
}
