import {Injectable} from "@angular/core";
import {AddressCandidateAdapter} from "./address-candidate.adapter";
import {Observable} from "rxjs";
import {AddressCandidate} from "../model/address-candidate.model";
import {GpsCoordinates} from "../model/gps-coordinates.mode";
import {NetworkArea} from "../model/network-area.model";
import {NetworkAreaAdapter} from "./network-area.adapter";
import {CostsIndicationAdapter} from "./costs-indication.adapter";
import {CostsIndication, CostsIndicationRequest} from "../model/cost-indication.model";

@Injectable()
export class CostsIndicationConnector {

  constructor(protected adapter: CostsIndicationAdapter) {}

  retrieve(costsIndicationRequest: CostsIndicationRequest): Observable<CostsIndication> {
    return this.adapter.retrieve(costsIndicationRequest);
  }

}
