import {Inject, Injectable} from "@angular/core";
import {AddressCandidateAdapter} from "../address-candidate.adapter";
import {Observable} from "rxjs";
import {AddressCandidate, AddressCandidates} from "../../model/address-candidate.model";
import {ConverterService, OccEndpointsService} from "@spartacus/core";
import {tap, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ConnectionQuotationToolConfig} from "../../connection-quotation-tool.module";
import {NetworkAreaAdapter} from "../network-area.adapter";
import {GpsCoordinates} from "../../model/gps-coordinates.mode";
import {NetworkArea} from "../../model/network-area.model";


@Injectable()
export class NetworkAreaImplAdatper implements NetworkAreaAdapter {
  constructor(
    protected http: HttpClient,
    @Inject('ConnectionQuotationToolConfigService') protected config : ConnectionQuotationToolConfig
  ) {
  }

  check(gpsCoordinates: GpsCoordinates): Observable<NetworkArea> {
    return this.http
      .get<NetworkArea>(this.config.checkNetworkArea, { params: {
          x: gpsCoordinates.x,
          y: gpsCoordinates.y,
        }})
  }
}

