import {Injectable} from "@angular/core";
import {Actions, createEffect, Effect, ofType} from "@ngrx/effects";
import {AddressCandidateConnector} from "../../connectors/address-candidate.connector";
import {catchError, exhaustMap, map, switchMap, tap} from "rxjs/operators";
import {QuotationActions} from "../actions";
import {of, Observable} from "rxjs";

@Injectable()
export class AddressCandidateEffects {

  constructor(
    private actions$: Actions,
    private connector: AddressCandidateConnector
  ) {}

  loadAddressCandidate$ = createEffect( () =>
    this.actions$.pipe(
      ofType<ReturnType<typeof QuotationActions.findAddressCandidate>>(QuotationActions.findAddressCandidate.type),
      exhaustMap((action) =>
        this.connector.find(action.zipcode, action.steetNumber, action.addtion).pipe(
          map(candidate => QuotationActions.findAddressCandidateSuccess({candidate: candidate})),
          catchError( error => of(QuotationActions.findAddressCandidateFail({error: error})))
        )
      )
    )
  );
}
