import {Observable} from "rxjs";
import {AddressCandidate} from "../model/address-candidate.model";
import {NetworkArea} from "../model/network-area.model";
import {GpsCoordinates} from "../model/gps-coordinates.mode";

export abstract class NetworkAreaAdapter {

  abstract check(gpsCoordinates: GpsCoordinates): Observable<NetworkArea>;

}
