import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {QuotationState} from "../../store/quotation.state";
import {select, Store} from "@ngrx/store";
import {registerQuotationComponent} from "../index";
import {LocationForm} from "../../model/quotation-model";
import {QuotationActions} from "../../store/actions";
import {CmsComponentData} from "@spartacus/storefront";
import {CmsFlexComponent} from "../../../../acorelsalesdemo/model/cms-models";

@Component({
  selector: 'app-connection-location',
  templateUrl: './connection-location.component.html',
  styleUrls: ['./connection-location.component.scss']
})
export class ConnectionLocationComponent implements OnInit {

  quotationModel$: Observable<QuotationState>;

  visible$ = true;

  locationForm$ = new LocationForm();

  constructor(private store: Store<{ quotationState: QuotationState }>,
              public component: CmsComponentData<CmsFlexComponent>) {
    this.quotationModel$ = store.pipe(select('quotationState'));
    registerQuotationComponent(this);
  }

  retrieveLocationDetails(state: QuotationState) {

    if (!state.addressCandidate || this.locationForm$.zipcode != state.addressCandidate.attributes.Postal
    || this.locationForm$.streetNumber != state.addressCandidate.attributes.AddNum) {

      console.log("zipcode: ", this.locationForm$.zipcode, " streetNumber: ", this.locationForm$.streetNumber, " additon: ", this.locationForm$.addition);
      if (this.locationForm$.zipcode && this.locationForm$.streetNumber) {
        this.store.dispatch(QuotationActions.findAddressCandidate({
          zipcode: this.locationForm$.zipcode,
          steetNumber: this.locationForm$.streetNumber,
          addtion: this.locationForm$.addition
        }))
      }
    }
  }

  ngOnInit() {
    this.quotationModel$.subscribe(
      model => {
        this.visible$ = (model.model.electric != null && model.model.electric.connectionType != null)

        if (model.addressCandidate) {
          this.locationForm$.addressLine = model.addressCandidate.attributes.StAddr;
          this.locationForm$.city = model.addressCandidate.attributes.City;
        }

        if (model.addressCandidate && !model.networkArea) {
          this.store.dispatch(QuotationActions.checkNetworkArea({gspCoordinates: model.addressCandidate.location}))
        }
      }
    )
  }

  selectCandidate(state: QuotationState) {
    this.store.dispatch(QuotationActions.retrieveCostsIndication({quotationState: state}))
  }
}
