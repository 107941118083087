import {Observable} from "rxjs";
import {AddressCandidate} from "../model/address-candidate.model";
import {NetworkArea} from "../model/network-area.model";
import {GpsCoordinates} from "../model/gps-coordinates.mode";
import {CostsIndication, CostsIndicationRequest} from "../model/cost-indication.model";

export abstract class CostsIndicationAdapter {

  abstract retrieve(costsIndicationRequest: CostsIndicationRequest): Observable<CostsIndication>;

}
