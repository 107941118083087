import { Component, OnInit } from '@angular/core';
import {
  Address,
  Consignment,
  Order,
} from '@spartacus/core';
import {combineLatest, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {Card, OrderDetailsService} from "@spartacus/storefront";

@Component({
  selector: 'cx-order-details-items',
  templateUrl: './quote-detail-items.component.html',
})
export class QuoteDetailItemsComponent implements OnInit {
  constructor(
    protected orderDetailsService: OrderDetailsService,
  ) {}

  order$: Observable<Order> = this.orderDetailsService.getOrderDetails();
  others$: Observable<Consignment[]>;
  completed$: Observable<Consignment[]>;
  cancel$: Observable<Consignment[]>;

  ngOnInit() {

  }

  getCardContent(
    address: Address,
    title: string,
  ): Card {
    let region = '';

    if (address.region && address.region.isocode) {
      region = address.region.isocode + ', ';
    }

    return {
      title: title,
      textBold: address.firstName + ' ' + address.lastName,
      text: [
        address.line1,
        address.line2,
        address.town + ', ' + region + address.country.isocode,
        address.postalCode,
        address.phone,
      ],
      header: '',
    };
  }
}
