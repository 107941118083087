import {Injectable} from "@angular/core";
import {Actions, createEffect, Effect, ofType} from "@ngrx/effects";
import {AddressCandidateConnector} from "../../connectors/address-candidate.connector";
import {catchError, exhaustMap, map, switchMap, tap} from "rxjs/operators";
import {QuotationActions} from "../actions";
import {of, Observable} from "rxjs";
import {NetworkAreaConnector} from "../../connectors/network-area.connector";

@Injectable()
export class NetworkAreaEffects {

  constructor(
    private actions$: Actions,
    private connector: NetworkAreaConnector
  ) {}

  loadNetworkArea$ = createEffect( () =>
    this.actions$.pipe(
      ofType<ReturnType<typeof QuotationActions.checkNetworkArea>>(QuotationActions.checkNetworkArea.type),
      exhaustMap((action) =>
        this.connector.check(action.gspCoordinates).pipe(
          map(networkArea => QuotationActions.checkNetworkAreaSuccess({networkArea: networkArea})),
          catchError( error => of(QuotationActions.checkNetworkAreaFail({error: error})))
        )
      )
    )
  );
}
