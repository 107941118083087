import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {QuotationState} from "../../store/quotation.state";
import {select, Store} from "@ngrx/store";
import {registerQuotationComponent} from "../index";
import {CmsComponentData} from "@spartacus/storefront";
import {CmsFlexComponent} from "../../../../acorelsalesdemo/model/cms-models";
import {CartModification, CartService, Product} from "@spartacus/core";
import {HttpClient} from "@angular/common/http";
import {NetworkArea} from "../../model/network-area.model";
import {ConnectionQuotationToolConfig} from "../../connection-quotation-tool.module";
import {catchError, first} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: 'app-connection-indication',
  templateUrl: './connection-indication.component.html',
  styleUrls: ['./connection-indication.component.scss']
})
export class ConnectionIndicationComponent implements OnInit {

  quotationModel$: Observable<QuotationState>;

  visible$ = true;

  constructor(
    private store: Store<{ quotationState: QuotationState }>,
    public component: CmsComponentData<CmsFlexComponent>,
    protected cartService: CartService,
    protected http: HttpClient,
    private router: Router,
    @Inject('ConnectionQuotationToolConfigService') protected config : ConnectionQuotationToolConfig) {
    this.quotationModel$ = store.pipe(select('quotationState'));
    registerQuotationComponent(this);
  }

  ngOnInit() {
    this.quotationModel$.subscribe(
      model => {
        this.visible$ = (model.costsIndicationIsLoading == true)
      }
    )
  }

  requestQuote(state: QuotationState) {
    console.log("Request active cart");
    this.http
      .post<Product>(this.config.submitConnectionConfiguration, {
        aansluitingType: state.costsIndication.aansluitingType,
        price: state.costsIndication.totalCosts,
        address: state.addressCandidate.address,
        gpsX: state.costsIndication.aansluitingLocation.x,
        gpsY: state.costsIndication.aansluitingLocation.y,

      }).pipe(first()).subscribe(product => {
        console.log(product.code);
        this.cartService.addEntry(product.code, 1);
        this.cartService.getActive().subscribe(cart => this.router.navigateByUrl("/checkout"))
      });
  }
}
