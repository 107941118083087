import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {QuotationState} from "../../store/quotation.state";
import {select, Store} from "@ngrx/store";
import {registerQuotationComponent} from "../index";
import {ConnectionUsage} from "../../model/quotation-model";
import {QuotationActions} from "../../store/actions";
import {CmsComponentData} from "@spartacus/storefront";
import {CmsFlexComponent} from "../../../../acorelsalesdemo/model/cms-models";

@Component({
  selector: 'app-connection-capacity',
  templateUrl: './connection-capacity.component.html',
  styleUrls: ['./connection-capacity.component.scss']
})
export class ConnectionCapacityComponent implements OnInit {

  quotationModel$: Observable<QuotationState>;

  visible$ = true;

  constructor(
    private store: Store<{ quotationState: QuotationState }>,
    public component: CmsComponentData<CmsFlexComponent>) {
    this.quotationModel$ = store.pipe(select('quotationState'));
    registerQuotationComponent(this);
  }

  ngOnInit() {
    this.quotationModel$.subscribe(
      model => {
        this.visible$ = (model.model.electric != null && model.model.electric.connectionUsage != null)
      }
    )
  }

  selectConnectionCapacity(capacity: string) {
    console.log("Capacity: " + capacity);
    this.store.dispatch(QuotationActions.setConnectionCapacity({connectionCapacity: capacity}));
  };
}
