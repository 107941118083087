import {Injectable} from "@angular/core";
import {Actions, createEffect, Effect, ofType} from "@ngrx/effects";
import {AddressCandidateConnector} from "../../connectors/address-candidate.connector";
import {catchError, exhaustMap, map, switchMap, tap} from "rxjs/operators";
import {QuotationActions} from "../actions";
import {of, Observable} from "rxjs";
import {NetworkAreaConnector} from "../../connectors/network-area.connector";
import {CostsIndicationConnector} from "../../connectors/costs-indication.connector";

@Injectable()
export class CostsIndicationEffects {

  constructor(
    private actions$: Actions,
    private connector: CostsIndicationConnector
  ) {}

  loadCostsIndication$ = createEffect( () =>
    this.actions$.pipe(
      ofType<ReturnType<typeof QuotationActions.retrieveCostsIndication>>(QuotationActions.retrieveCostsIndication.type),
      map(action => action.quotationState),
      exhaustMap((state) =>
        this.connector.retrieve({
          aansluitingType: state.model.electric.connectionCapacity,
          adres: {
            bijBenadering: false,
            postcode: state.addressCandidate.attributes.Postal,
            huisnummer: state.addressCandidate.attributes.AddNum,
            plaats: state.addressCandidate.attributes.City,
            straat: state.addressCandidate.attributes.StAddr,
            toevoeging: ''
          },
          gpsCoordinates: state.addressCandidate.location
        }).pipe(
          map(costsIndication => QuotationActions.retrieveCostsIndicationSuccess({costsIndication:costsIndication})),
          catchError( error => of(QuotationActions.retrieveCostsIndicationFail({error: error})))
        )
      )
    )
  );
}
