import {CheckoutOrderSummaryComponent} from "@spartacus/storefront";
import {ChangeDetectionStrategy, Component} from "@angular/core";
import {CartService, OrderEntry} from "@spartacus/core";
import {Observable} from "rxjs";

@Component({
  selector: 'cx-checkout-quote-summary',
  templateUrl: './checkout-quote-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutQuoteSummaryComponent extends CheckoutOrderSummaryComponent{
  entries$: Observable<OrderEntry[]>;

  constructor(cartService: CartService) {
    super(cartService);
    this.entries$ = this.cartService.getEntries();
  }
}
