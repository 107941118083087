import {Address} from "@spartacus/core";
import {GpsCoordinates} from "./gps-coordinates.mode";

export enum ConnectionUsage {
  Consume,
  Produce,
  Both
}

export enum ConnectionType {
  Small,
  Large
}

export interface QuotationModel {
  electric: Electric,
  gas: Gas
}

export interface Electric {
  approximately: boolean,
  connectionCapacity: string,
  connectionType: ConnectionType,
  connectionEan: string,
  address: Address,
  gpsCoordinates: GpsCoordinates,
  connectionUsage: ConnectionUsage
}

export interface Gas {

}

export class LocationForm {

  constructor(
    public zipcode?: string,
    public streetNumber?: string,
    public addition?: string,
    public addressLine?: string,
    public city?: string
  ) {  }

}

