import { Component, Input } from '@angular/core';
import {Cart, Order, OrderEntry, Product, ProductService} from '@spartacus/core';
import {Observable} from "rxjs";

@Component({
  selector: 'cx-quote-entry',
  templateUrl: './quote-entry.component.html',
})
export class QuoteEntryComponent {

  @Input()
  entry: OrderEntry;

  @Input()
  quoted: boolean;

  @Input()
  quote: Order;

  productService: ProductService;
  product$: Observable<Product>;

  constructor(productService:ProductService) {
    this.productService = productService;
  }

  ngOnInit() {
    this.product$ = this.productService.get(this.entry.product.code);
  }

}

