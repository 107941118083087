import { Component, Input } from '@angular/core';
import {Cart, OrderEntry} from '@spartacus/core';

@Component({
  selector: 'cx-quote-summary',
  templateUrl: './quote-summary.component.html',
})
export class QuoteSummaryComponent {
  @Input()
  cart: Cart;

  @Input()
  entries: OrderEntry[];
  
}

